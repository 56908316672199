export default [
  {
    title: 'ข้อมูลลูกค้า',
    route: 'supports-customers',
    icon: 'UsersIcon',
    action: 'all',
    resource: 'support',
  },
  {
    title: 'เติมเครดิต',
    route: 'supports-add-credit',
    icon: 'DollarSignIcon',
    action: 'all',
    resource: 'support',
  },
]
