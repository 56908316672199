/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
import wallpapers from './wallpapers'
import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'
import UserMenu from './UserMenu'
import CreditManagementMenu from './CreditManagementMenu'
import NotificationMenu from './NotificationMenu'
import SlidDataManagementMenu from './SlidDataManagementMenu'
// import DetectImageMenu from './DetectImageMenu'
import imagesManagementMenu from './imagesManagementMenu'
import UserTransactionDataMenu from './UserTransactionDataMenu'
import PartnerBankMenu from './PartnerBankMenu'
import supportsMenu from './supports-menu'
import partnersManagementMenu from './partners-management-menu'
import withdrawManagementMenu from './withdraw-management-menu'
import exportManagementMenu from './export-management-menu'
import zodiacManagementMenu from './zodiac-management-menu'
import refundManagementMenu from './refund-management-menu'
import appMobileConfigMenu from './app-mobile-config-menu'
import redeemMenu from './redeem-menu'
import reportMenu from './report-menu'
import toolsMenu from './tools-menu'
// Array of sections
export default [
  ...dashboard,
  ...reportMenu,
  ...SlidDataManagementMenu,
  // ...DetectImageMenu,
  ...imagesManagementMenu,
  ...zodiacManagementMenu,
  ...UserTransactionDataMenu,
  ...refundManagementMenu,
  ...UserMenu,
  ...partnersManagementMenu,
  ...withdrawManagementMenu,
  ...CreditManagementMenu,
  ...redeemMenu,
  ...NotificationMenu,
  ...toolsMenu,
  ...wallpapers,
  ...PartnerBankMenu,
  ...supportsMenu,
  ...appMobileConfigMenu,
  ...exportManagementMenu,
  // ...appsAndPages,
  // ...uiElements,
  // ...formAndTable,
  // ...chartsAndMaps,
  // ...others,
]
