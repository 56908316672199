export default [
  {
    header: 'อื่นๆ',
    action: 'all',
    resource: 'admin',
  },
  {
    title: 'ส่งให้บัญชี',
    route: 'export-management',
    icon: 'UserIcon',
    action: 'all',
    resource: 'admin',
  },
]
