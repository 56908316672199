export default [
  {
    header: 'ทั่วไป',
    resource: 'partner',
    action: 'all',
  },
  // {
  //   title: 'ข้อมูล การโอน',
  //   route: 'bankinfo',
  //   icon: 'AlertTriangleIcon',
  //   resource: 'partner',
  // },
  {
    title: 'ตั้งค่าบัญชี',
    route: 'partner-settings',
    icon: 'SettingsIcon',
    resource: 'partner-setting',
    action: 'all',
  },
  {
    title: 'ถอนเงินจากบัญชี',
    icon: 'AlertTriangleIcon',
    route: 'partner-withdraw',
    resource: 'partner',
    action: 'all',
  },
  {
    title: 'ประวัติการถอนเงิน',
    icon: 'AlertTriangleIcon',
    route: 'partner-withdraw-transactions',
    resource: 'partner',
    action: 'all',
  },
  {
    title: 'ติดต่อสอบถาม',
    href: 'https://lin.ee/t3FGSRx',
    icon: 'MessageSquareIcon',
    resource: 'partner-setting',
    action: 'all',
  },
]
