export default [
  {
    title: 'แผงควบคุม',
    route: 'dashboard',
    icon: 'HomeIcon',
    action: 'all',
    resource: 'dashboard',
  },
  {
    title: 'ประกาศ',
    route: 'announce',
    icon: 'FlagIcon',
    action: 'all',
    resource: 'dashboard',
  },
  {
    title: 'คู่มือการใช้งาน',
    route: 'how-to',
    icon: 'BookIcon',
    action: 'all',
    resource: 'dashboard',
  },
  {
    title: 'Presentation',
    href: 'https://www.canva.com/design/DAFcw0xZcWg/nAIvLm9FFneNXpWK7eqEig/view',
    icon: 'PlayCircleIcon',
    action: 'all',
    resource: 'dashboard',
  },
]
