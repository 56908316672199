export default [
  {
    header: 'จัดการแจ้งเตือน',
    action: 'all',
    resource: 'admin',
  },
  {
    title: 'ส่งแจ้งเตือน',
    route: 'notificationData',
    icon: 'AlertTriangleIcon',
    action: 'all',
    resource: 'admin',
  },
  {
    title: 'ตั้งค่าการแจ้งเตือน',
    route: 'notificationSetting',
    icon: 'BellIcon',
    action: 'all',
    resource: 'admin',
  },
]
