export default [
  {
    title: 'ตรวจสอบวอลเปเปอร์',
    icon: 'ImageIcon',
    // tag: '2',
    // tagVariant: 'light-warning',
    action: 'all',
    resource: 'admin',
    children: [
      {
        title: 'รออนุมัติ',
        route: 'images-management-review',
        action: 'all',
        resource: 'admin',
      },
      {
        title: 'กำลังตรวจสอบ',
        route: 'images-management-checking',
        action: 'all',
        resource: 'admin',
      },
      {
        title: 'อนุมัติ',
        route: 'images-management-approve',
        action: 'all',
        resource: 'admin',
      },
      {
        title: 'ไม่อนุมัติ',
        route: 'images-management-reject',
        action: 'all',
        resource: 'admin',
      },
      {
        title: 'รายละเอียดรูป',
        route: { name: 'images-management-details', params: { id: 0 } },
        action: 'all',
        resource: 'admin',
      },
    ],
  },
]
