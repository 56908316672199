export default [
  {
    header: 'จัดการวอลเปเปอร์',
    action: 'all',
    resource: 'wallpaper',
  },
  {
    title: 'วอลเปเปอร์ทั้งหมด',
    route: 'wallpapers',
    icon: 'PackageIcon',
    action: 'all',
    resource: 'wallpaper',
  },
  {
    title: 'อัปโหลดวอลเปเปอร์',
    route: 'wallpaper-upload-wallpaper',
    icon: 'UploadIcon',
    action: 'all',
    resource: 'wallpaper',
  },
  {
    title: 'อัปโหลดราศี',
    route: 'wallpaper-upload-zodiac',
    icon: 'UploadIcon',
    action: 'all',
    resource: 'wallpaper',
  },
  {
    title: 'สร้างรูป',
    route: 'wallpaper-create',
    icon: 'ToolIcon',
    action: 'all',
    resource: 'admin',
  },
  {
    title: 'ตั้งค่าอัปโหลด',
    icon: 'SlidersIcon',
    action: 'all',
    resource: 'admin',
    children: [
      {
        title: 'หมวดหมู่วอลเปเปอร์',
        route: 'wallpaper-setting-upload',
        action: 'all',
        resource: 'admin',
      },
    ],
  },
  {
    header: 'สรุปข้อมูลการขาย',
    action: 'all',
    resource: 'wallpaper',
  },
  {
    title: 'ข้อมูลการขาย',
    route: 'wallpaper-sale-info',
    icon: 'MonitorIcon',
    action: 'all',
    resource: 'wallpaper',
  },
]
