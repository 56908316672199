export default [
  {
    title: 'สรุปรายงาน',
    icon: 'ActivityIcon',
    // tag: '2',
    // tagVariant: 'light-success',
    action: 'all',
    resource: 'admin',
    children: [
      {
        title: 'เครดิต',
        route: 'report-credit',
        action: 'all',
        resource: 'admin',
      },
      {
        title: 'การเข้าสู่ระบบ',
        route: 'report-user-login',
        action: 'all',
        resource: 'admin',
      },
    ],
  },
]
