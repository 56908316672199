export default [
  {
    header: 'เครื่องมือต่างๆ',
    action: 'manage',
    resource: 'Auth',
  },
  {
    title: 'Short Link',
    route: 'tools-short-link',
    icon: 'LinkIcon',
    action: 'manage',
    resource: 'Auth',
  },
]
