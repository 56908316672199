export default [
  {
    header: 'จัดการแอป',
    action: 'all',
    resource: 'admin',
  },
  {
    title: 'ตั้งค่า',
    route: 'app-mobile-config',
    icon: 'AlertTriangleIcon',
    action: 'all',
    resource: 'admin',
  },
  {
    title: 'i18n',
    route: 'app-mobile-i18n-config',
    icon: 'AlertTriangleIcon',
    action: 'all',
    resource: 'admin',
  },
]
